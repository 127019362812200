import React, { useState, useEffect, useRef } from 'react';
import LeftSideBar from '../LeftSideBar';
import { useDispatch, useSelector } from 'react-redux';
import useFetchStudentDataEmail from '../../api/FetchStudentDetailsEmail';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { notification, Spin, Input } from 'antd';
import useCreateStudentCode from '../../api/CreateStudentCode';
import useRequestOTPEmail from '../../api/RequestOtpEmail';
import useFetchClientConfig from '../../api/FetchClientConfig';
import useVerifyOTPEmail from '../../api/VerifyOtpEmail';
import useVerifyCouponCode from '../../api/VerifyCoupon';
import { setClientConfiguration, setFormConfiguration, nextFlow } from '../../redux/student/studentSlice';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber, formatPhoneNumber } from 'react-phone-number-input';
import Lottie from 'lottie-react';
import animationData from '../../assets/Animation/loading_animation.json';
import useFetchFormConfig from '../../api/FetchFormConfig';
import useFetchTestSeriesDetails from '../../api/FetchTestSeriesDetails';
import useFetchUserCountry from '../../api/useFetchUserCountry';

const careerStage = [
    { key: 1, value: 6, title: "6th" },
    { key: 2, value: 7, title: "7th" },
    { key: 3, value: 8, title: "8th" },
    { key: 4, value: 9, title: "9th" },
    { key: 5, value: 10, title: "10th" },
    { key: 6, value: 11, title: "11th" },
    { key: 7, value: 12, title: "12th" },
];

const SigninCouponCode = ( { currentComponent, currentComponentNext } ) => {
    const [selectedTab, setSelectedTab] = useState( 'signup' );
    const [email, setEmail] = useState( '' );
    const [name, setName] = useState( '' );
    const [phoneNumber, setPhoneNumber] = useState( '' );
    const [grade, setGrade] = useState( '' );
    const [dob, setDob] = useState( '' );
    const [userSection, setUserSection] = useState( '' );
    const [userId, setUserId] = useState( null );

    const [couponCode, setCouponCode] = useState( '' );
    const [otpVisible, setOtpVisible] = useState( false );
    const [otp, setOtp] = useState( ['', '', '', ''] );
    const inputRefs = useRef( [] );

    const [isEmailValid, setIsEmailValid] = useState( true );
    const [isNameValid, setIsNameValid] = useState( true );
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState( true );
    const [isGradeValid, setIsGradeValid] = useState( true );
    const [phoneError, setPhoneError] = useState( '' );
    const [emailError, setEmailError] = useState( '' );
    const [couponCodeError, setCouponCodeError] = useState( true );
    const [timer, setTimer] = useState( 30 );
    const [resendClickable, setResendClickable] = useState( false );

    const [emailEditable, setEmailEditable] = useState( true );

    const { client_name, test_type } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const query = new URLSearchParams( location.search );
    const currentQueryParams = location.search;
    const test_id = query.get( 'test_id' ) || localStorage.getItem( 'testId' );
    const test_series_id = query.get( 'test_series_id' );

    const clientConfig = useSelector( state => state.clientConfiguration );

    const clientConfiguration = sessionStorage.getItem( 'clientConfiguration' );
    const userAssessmentResponseID = localStorage.getItem( 'userAssessmentResponseID' );
    const formConfiguration = sessionStorage.getItem( 'formConfiguration' );

    const { countryCode } = useFetchUserCountry();
    const { response: studentData, isLoading: studentDataLoading, error: studentDataError, FetchStudentDataEmail } = useFetchStudentDataEmail();
    const { createStudentCode, response: createStudentResponse, isLoading: creatingStudent, error: creatingStudentError } = useCreateStudentCode();
    const { emailData, isRequestingOTP: emailVerifying, requestOTP } = useRequestOTPEmail();
    const { response: clientConfigData, isLoading, fetchClientConfig } = useFetchClientConfig();
    const { data: emailVerified, error: emailNotVerified, isLoading: verifyingOTP, verifyOTP } = useVerifyOTPEmail();
    const { response: couponCodeResponse, isLoading: couponCodeVerifying, verifyCouponCode } = useVerifyCouponCode();
    const { response: formConfigData, isLoading: formConfigLoading, fetchFormConfig } = useFetchFormConfig();
    const { response: testSeriesData, isLoading: testSeriesLoading, FetchTestSeriesDetails } = useFetchTestSeriesDetails();

    const isClientActive = clientConfig?.is_active;

    useEffect( () => {
        if ( test_series_id ) {
            FetchTestSeriesDetails( test_series_id );
        }
    }, [test_series_id] );

    useEffect( () => {
        if ( query.get( 'user_name' ) ) {
            setName( query.get( 'user_name' ) );
            localStorage.setItem( 'studentName', query.get( 'user_name' ) )
        }
        if ( query.get( 'user_email' ) ) {
            setEmail( query.get( 'user_email' ) );
            localStorage.setItem( 'user_email', query.get( 'user_email' ) )
        }
        if ( query.get( 'user_phone' ) ) {
            setPhoneNumber( decodeURIComponent( query.get( 'user_phone' ) ) );
            localStorage.setItem( 'phone_number', decodeURIComponent( query.get( 'user_phone' ) ) );
        }
        if ( query.get( 'user_id' ) ) {
            setUserId( query.get( 'user_id' ) );
        }
    }, [query] );

    useEffect( () => {
        const params = {};
        for ( let [key, value] of query.entries() ) {
            params[key] = value;
        }

        const { test_id, test_series_id, ...filteredParams } = params;

        if ( Object.keys( filteredParams ).length > 0 ) {
            sessionStorage.setItem( 'utm_parameters', JSON.stringify( filteredParams ) );
        }
    }, [location.search] );

    useEffect( () => {
        if ( testSeriesData && testSeriesData?.id ) {
            sessionStorage.setItem( 'TestSeriesDetails', JSON.stringify( testSeriesData ) );
        }
    }, [testSeriesData] )

    useEffect( () => {
        if ( client_name && !formConfiguration && isClientActive && test_id ) {
            fetchFormConfig( client_name, test_type, test_id );
        }
    }, [client_name, isClientActive] )

    useEffect( () => {
        if ( formConfigData && formConfigData?.data?.form_config ) {
            dispatch( setFormConfiguration( formConfigData?.data ) );
            sessionStorage.setItem( 'formConfiguration', JSON.stringify( formConfigData?.data ) );

            const timeout = setTimeout( () => {
                sessionStorage.removeItem( 'formConfiguration' );
                fetchFormConfig( client_name, test_type, test_id );
            }, 60 * 60 * 1000 );
            return () => clearTimeout( timeout );
        }
    }, [formConfigData, formConfigLoading] )

    const handleSendOTP = () => {
        if ( !email ) {
            setIsEmailValid( false );
            setEmailError( '*required' );
        } else if ( !isValidEmail( email ) ) {
            setIsEmailValid( false );
            setEmailError( 'Incorrect Email' );
        } else {
            setIsEmailValid( true );
            FetchStudentDataEmail( email, client_name );
        }
    };

    const handleVerifyOTP = () => {
        verifyOTP( email, otp.join( '' ) )
    }

    const isValidEmail = ( value ) => {
        const regex = /^[a-zA-Z0-9]+([\.-]?[a-zA-Z0-9]+){0,5}@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]+$/;
        return regex.test( value );
    };

    useEffect( () => {
        if ( client_name && !clientConfiguration && !clientConfigData ) {
            fetchClientConfig( client_name );
        }
    }, [] );

    useEffect( () => {
        Object.keys( localStorage ).forEach( ( key ) => {
            if ( key !== 'userAssessmentResponseID' && key != 'testId' ) {
                localStorage.removeItem( key );
            }
        } );
    }, [] )

    const handleOtpChange = ( e, index ) => {
        const { value } = e.target;
        const newOtp = [...otp];

        if ( /^[0-9]$/.test( value ) ) {
            newOtp[index] = value;
            setOtp( newOtp );
            if ( index < otp.length - 1 ) {
                inputRefs.current[index + 1].focus();
            }
        } else if ( value === '' && e.nativeEvent.inputType === 'deleteContentBackward' ) {
            if ( index > 0 ) {
                inputRefs.current[index - 1].focus();
            }
            newOtp[index] = '';
            setOtp( newOtp );
        }
    };

    const handleCheckEmail = () => {
        if ( !email ) {
            setIsEmailValid( false );
            setEmailError( '*required' );
        } else if ( !isValidEmail( email ) ) {
            setIsEmailValid( false );
            setEmailError( 'Incorrect Email' );
        } else {
            setIsEmailValid( true );
            FetchStudentDataEmail( email, client_name );
        }
    }

    useEffect( () => {
        if ( emailData ) {
            notification.success( {
                message: 'OTP sent to the registered email id',
            } );
            setOtpVisible( true );
        }
    }, [emailData] )

    const url = window.location.href;

    useEffect( () => {
        if ( url.includes( 'signup' ) ) {
            setSelectedTab( 'signup' )
        } else if ( url.includes( 'signin' ) ) {
            setSelectedTab( 'signin' )
        } else {
            console.log( 'The URL does not contain "signup" or "signin"' );
        }
    }, [] );

    useEffect( () => {
        if ( clientConfigData && clientConfigData?.data ) {
            dispatch( setClientConfiguration( clientConfigData.data ) );
            sessionStorage.setItem( 'clientConfiguration', JSON.stringify( clientConfigData.data ) );

            const timeout = setTimeout( () => {
                sessionStorage.removeItem( 'clientConfiguration' );
                fetchClientConfig( client_name );
            }, 10 * 60 * 1000 );
            return () => clearTimeout( timeout );
        }
    }, [clientConfigData, client_name] );

    //navigate( `/${ client_name }/test/selection/?user_email=${ studentData?.email }&user_name=${ studentData?.name }&user_id=${ studentData?.student_code }` );

    useEffect( () => {
        if ( studentData && studentData?.student_code && url.includes( 'signin' ) ) {
            setEmailEditable( false );
            requestOTP( email, client_name );
        } else if ( studentData && studentData?.status_code === 404 && url.includes( 'signin' ) ) {
            notification.error( {
                message: 'Email not found. Please sign up first.',
            } );
        }
    }, [studentData] );

    useEffect( () => {
        if ( studentData && studentData?.student_code && url.includes( 'signup' ) ) {
            setEmailEditable( false );
            const studentRollNumber = studentData?.student_code;
            localStorage.setItem( 'student_roll_number', studentRollNumber );
            verifyCouponCode( { student_email: email, couponCode: couponCode, userId: userId || studentRollNumber } )
            if ( !couponCode && ( test_id || test_series_id ) ) {
                const userName = studentData?.name;
                const userEmail = studentData?.email;
                localStorage.setItem( 'phone_number', phoneNumber );
                localStorage.setItem( 'user_email', userEmail );
                localStorage.setItem( 'studentName', userName );
                localStorage.setItem( 'testId', test_id );
                localStorage.setItem( 'testSeriesId', test_series_id );
                dispatch( nextFlow() );
                let url = `/${ client_name }/${ test_type }/${ test_id }/flow/?user_name=${ userName }&user_email=${ userEmail }&user_id=${ studentRollNumber }`;
                navigate( url, { replace: true, state: { test_id: test_id, test_series_id: test_series_id } } );
            }
        } else if ( studentData && studentData?.status_code === 404 && url.includes( 'signup' ) ) {
            handleSignup();
        }
    }, [studentData] );

    const handleSignup = () => {
        let isValid = true;

        if ( !name ) {
            setIsNameValid( false );
            isValid = false;
        } else {
            setIsNameValid( true );
        }

        if ( !grade ) {
            setIsGradeValid( false );
            isValid = false;
        } else {
            setIsGradeValid( true );
        }

        if ( !email ) {
            setIsEmailValid( false );
            setEmailError( '*required' );
            isValid = false;
        } else if ( !isValidEmail( email ) ) {
            setIsEmailValid( false );
            setEmailError( 'Incorrect Email' );
            isValid = false;
        } else {
            setIsEmailValid( true );
        }

        if ( !phoneNumber ) {
            setIsPhoneNumberValid( false );
            setPhoneError( '*required' );
            isValid = false;
        } else if ( !isValidPhoneNumber( phoneNumber ) ) {
            setIsPhoneNumberValid( false );
            setPhoneError( 'Incorrect phone number' );
            isValid = false;
        } else {
            setIsPhoneNumberValid( true );
        }

        if ( isValid ) {
            localStorage.setItem( 'phone_number', phoneNumber );
            localStorage.setItem( 'user_email', email );
            localStorage.setItem( 'studentName', name );
            createStudentCode( name, grade, email, client_name, userAssessmentResponseID, dob, clientConfig?.client_id, phoneNumber, userSection );
            isValid = false;
        } else {
            notification.error( {
                message: 'Please fill in the required fields'
            } );
        }
    };


    useEffect( () => {
        if ( createStudentResponse ) {
            const studentRollNumber = createStudentResponse?.student_code;
            localStorage.setItem( 'student_roll_number', studentRollNumber );
            verifyCouponCode( { student_email: email, couponCode: couponCode, userId: userId || studentRollNumber } )
            if ( !couponCode && ( test_id || test_series_id ) ) {
                const userName = createStudentResponse?.name;
                const userEmail = createStudentResponse?.email;
                localStorage.setItem( 'testId', test_id );
                localStorage.setItem( 'testSeriesId', test_series_id );

                let url = `/${ client_name }/${ test_type }/${ test_id }/flow/?user_name=${ userName }&user_email=${ userEmail }&user_id=${ studentRollNumber }`;
                dispatch( nextFlow() );
                navigate( url, { replace: true, state: { test_id: test_id, test_series_id: test_series_id } } );
            }
        }
    }, [createStudentResponse] )

    useEffect( () => {
        if ( couponCodeResponse ) {
            const { data } = couponCodeResponse;
            const testId = data?.data?.test_id;
            const testType = data?.data?.test_type;
            const userName = createStudentResponse?.name || studentData?.name;
            const userEmail = createStudentResponse?.email || studentData?.email;
            const userId = createStudentResponse?.student_code || studentData?.student_code;

            // Build the base URL
            let url = `/${ client_name }/${ testType }/flow/?user_name=${ userName }&user_email=${ userEmail }&user_id=${ userId }`;

            // If testId exists, append it to the URL
            if ( testId ) {
                url = `/${ client_name }/${ testType }/${ testId }/flow/?user_name=${ userName }&user_email=${ userEmail }&user_id=${ userId }`;
                localStorage.setItem( 'testId', test_id );
                localStorage.setItem( 'testSeriesId', test_series_id );
            }
            // Navigate to the final URL
            testId ? navigate( url, { replace: true, state: { test_id: test_id, test_series_id: test_series_id } } ) : notification.error( { message: 'Your Coupon Code seems incorrect.', description: 'Please try with a new one.' } );
        }
    }, [couponCodeResponse] );

    const handleNavigateToSignUp = () => {
        navigate( `/${ client_name }/test/signup/${ currentQueryParams }` );
    };

    const handleNavigateToSignIn = () => {
        navigate( `/${ client_name }/test/signin/${ currentQueryParams }` );
    };

    useEffect( () => {
        if ( timer > 0 && otpVisible ) {
            const countdown = setTimeout( () => {
                setTimer( timer - 1 );
            }, 1000 );
            return () => clearTimeout( countdown );
        } else {
            setResendClickable( true );
        }
    }, [timer, otpVisible] );

    const handleResendClick = () => {
        if ( resendClickable ) {
            requestOTP( email, client_name );
            setTimer( 30 );
            setResendClickable( false );
        }
    };

    useEffect( () => {
        if ( creatingStudentError && creatingStudentError?.response?.data?.detail !== "" ) {
            notification.error( {
                message: creatingStudentError?.response?.data?.detail || 'Oops! something is not correct'
            } )
        }
    }, [creatingStudentError] );

    useEffect( () => {
        if ( emailVerified && emailVerified?.success ) {
            // Build the base URL
            let url = `/${ client_name }/${ test_type }/overall_instructions/?user_name=${ studentData?.name }&user_email=${ studentData?.email }&user_id=${ studentData?.student_code }`;

            // If testId exists, append it to the URL
            if ( test_id ) {
                url = `/${ client_name }/${ test_type }/${ test_id }/flow/?user_name=${ studentData?.name }&user_email=${ studentData?.email }&user_id=${ studentData?.student_code }`;
            }
            // Navigate to the final URL
            localStorage.setItem( 'testId', test_id );
            localStorage.setItem( 'testSeriesId', test_series_id );
            navigate( url, { replace: true, state: { test_id: test_id, test_series_id: test_series_id } } );

        } else if ( emailNotVerified?.response?.status === 400 ) {
            notification.error( {
                message: 'Incorrect OTP. Please try again',
            } );
        }
    }, [emailVerified, emailNotVerified] )

    return (
        <div>
            {
                ( studentDataLoading || creatingStudent || emailVerifying || verifyingOTP || couponCodeVerifying ) &&
                <div className="overlay">
                    <Spin size="large" />
                </div>
            }
            {
                ( isLoading || formConfigLoading || testSeriesLoading ) ?
                    <div className="flex items-center justify-center h-screen" >
                        <Lottie animationData={ animationData } loop autoplay style={ { width: '250px', height: '250px' } } />
                    </div>
                    :
                    <div className="flex flex-col md:flex-row min-h-screen">
                        {
                            ( url.includes( 'signup' ) || url.includes( 'signin' ) ) &&
                            <LeftSideBar />
                        }
                        <div className="flex flex-col lg:justify-center sm:mt-[1rem] lg:m-0 w-full font-poppins">
                            <div className="w-full flex flex-col items-center justify-center px-3 py-8 mx-auto">
                                { url.includes( 'signin' ) && (
                                    <div className="sm:w-full bg-white rounded-lg shadow-lg lg:w-3/4">
                                        <div className="p-8 space-y-6 md:space-y-8">
                                            <h1 className="text-2xl font-bold text-gray-900 md:text-3xl flex items-center">
                                                Welcome 🎉 Sign in to your account
                                            </h1>
                                            <h1 className="text-lg font-medium text-gray-700 mt-1">
                                                Not a member? <span className='text-blue-600 font-bold cursor-pointer' onClick={ handleNavigateToSignUp }> Sign Up</span>
                                            </h1>
                                            <div className="space-y-4 md:space-y-6">
                                                <div>
                                                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Your email { !isEmailValid &&
                                                        <span className='ml-3 text-xs text-[#FF0000] font-light'>
                                                            { emailError }
                                                        </span>
                                                    }
                                                    </label>
                                                    <input
                                                        type="email"
                                                        value={ email }
                                                        onChange={ ( e ) => setEmail( e.target.value ) }
                                                        style={ { resize: "none" } }
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                        placeholder="name@company.com"
                                                        required=""
                                                        disabled={ !emailEditable }
                                                    />
                                                </div>
                                                { otpVisible && (
                                                    <>
                                                        <div className="flex justify-center mb-4 transition-transform duration-500 transform translate-y-4">
                                                            { otp.map( ( value, index ) => (
                                                                <Input
                                                                    key={ index }
                                                                    type="text"
                                                                    maxLength={ 1 }
                                                                    value={ value }
                                                                    onChange={ ( e ) => handleOtpChange( e, index ) }
                                                                    ref={ el => inputRefs.current[index] = el }
                                                                    className="w-12 h-12 mx-1 text-center font-semibold border border-gray-300 rounded-lg"
                                                                />
                                                            ) ) }
                                                        </div>
                                                        <div className="text-center">
                                                            { timer > 0 ? `Resend OTP in ${ timer }s` :
                                                                <span
                                                                    className={ `cursor-pointer ${ resendClickable ? 'text-blue-500' : 'text-gray-400' }` }
                                                                    onClick={ handleResendClick }
                                                                >
                                                                    Resend OTP
                                                                </span> }
                                                        </div>
                                                    </>
                                                ) }
                                                {
                                                    emailData ?
                                                        <button
                                                            onClick={ handleVerifyOTP }
                                                            className="w-full text-white font-bold rounded-lg text-lg px-5 py-2.5 text-center"
                                                            style={ {
                                                                backgroundColor: clientConfig.primary_color,
                                                            } }
                                                        >
                                                            Verify OTP
                                                        </button>
                                                        :
                                                        <button
                                                            onClick={ handleSendOTP }
                                                            className="w-full text-white font-bold rounded-lg text-lg px-5 py-2.5 text-center"
                                                            style={ {
                                                                backgroundColor: clientConfig.primary_color,
                                                            } }
                                                        >
                                                            Send OTP
                                                        </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ) }
                                { url.includes( 'signup' ) && (
                                    <div className="sm:w-full bg-white rounded-lg shadow lg:w-3/4">
                                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                            <h1 className="text-2xl font-bold text-gray-900 md:text-3xl flex items-center">
                                                Hi 👋🏻, Let's start with your details
                                            </h1>
                                            {/* <h1 className="text-lg font-medium text-gray-700 mt-1">
                                                Already have an account? <span className='text-blue-600 font-bold cursor-pointer' onClick={ handleNavigateToSignIn }> Sign In</span>
                                            </h1> */}
                                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                                                <div className="col-span-1">
                                                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">Your name { !isNameValid &&
                                                        <span className='ml-3 text-xs text-[#FF0000] font-light'>
                                                            *required
                                                        </span> }
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={ name }
                                                        onChange={ ( e ) => {
                                                            setIsNameValid( true )
                                                            setName( e.target.value )
                                                        } }
                                                        className={ `bg-gray-50 border ${ isNameValid ? 'border-gray-300' : 'border-red-500' } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5` }
                                                        placeholder="John Doe"
                                                        disabled={ query.get( 'user_name' ) }
                                                        required
                                                    />


                                                </div>
                                                <div className="col-span-1">
                                                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Your email
                                                        { !isEmailValid &&
                                                            <span className='ml-3 text-xs text-[#FF0000] font-light'>
                                                                { emailError }
                                                            </span>
                                                        }
                                                    </label>
                                                    <input
                                                        type="email"
                                                        value={ email }
                                                        onChange={ ( e ) => {
                                                            setIsEmailValid( true )
                                                            setEmail( e.target.value )
                                                        } }
                                                        className={ `bg-gray-50 border ${ isEmailValid ? 'border-gray-300' : 'border-red-500' } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5` }
                                                        placeholder="name@company.com"
                                                        disabled={ query.get( 'user_email' ) }
                                                        required
                                                    />
                                                </div>
                                                <div className="col-span-1">
                                                    <label htmlFor="phoneNumber" className="block mb-2 text-sm font-medium text-gray-900">Your phone number
                                                        { !isPhoneNumberValid && (
                                                            <span className='ml-3 text-xs text-[#FF0000] font-light'>
                                                                { phoneError }
                                                            </span>
                                                        ) }
                                                    </label>
                                                    <PhoneInput
                                                        placeholder="Enter phone number"
                                                        value={ phoneNumber }
                                                        onChange={ ( e ) => {
                                                            setIsPhoneNumberValid( true )
                                                            setPhoneNumber( e )
                                                        } }
                                                        defaultCountry={countryCode}
                                                        international
                                                        countryCallingCodeEditable={ false }
                                                        withCountryCallingCode={ true }
                                                        disabled={ query.get( 'user_phone' ) }
                                                    />
                                                </div>
                                                <div className="col-span-1 flex flex-row gap-2 w-full">
                                                    <div className='flex flex-col w-1/2'>
                                                        <label htmlFor="grade" className="block mb-2 text-sm font-medium text-gray-900">Career Stage { !isGradeValid &&
                                                            <span className='ml-3 text-xs text-[#FF0000] font-light'>
                                                                *required
                                                            </span> }
                                                        </label>
                                                        <select
                                                            id="grade"
                                                            value={ grade }
                                                            onChange={ ( e ) => setGrade( e.target.value ) }
                                                            className={ `bg-gray-50 border ${ isGradeValid ? 'border-gray-300' : 'border-red-500' } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5` }
                                                        >
                                                            <option value="" disabled>Select Grade</option>
                                                            { careerStage.map( stage => (
                                                                <option key={ stage.key } value={ stage.value }>{ stage.title }</option>
                                                            ) ) }
                                                        </select>
                                                    </div>
                                                    <div className='flex flex-col w-1/2'>
                                                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">Section (optional)
                                                        </label>
                                                        <input
                                                            type="text"
                                                            value={ userSection }
                                                            onChange={ ( e ) => {
                                                                const value = e.target.value;
                                                                const filteredValue = value.replace( /[^a-zA-Z]/g, '' );
                                                                setUserSection( filteredValue );
                                                            } }
                                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                            placeholder="eg. A,B,C etc."
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-span-1">
                                                    <label htmlFor="dob" className="block mb-2 text-sm font-medium text-gray-900">Date of birth (optional)</label>
                                                    <input
                                                        type="date"
                                                        value={ dob }
                                                        onChange={ ( e ) => setDob( e.target.value ) }
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                    />
                                                </div>
                                                <div className="col-span-1">
                                                    <label htmlFor="couponCode" className="block mb-2 text-sm font-medium text-gray-900">Exam Coupon (optional)
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={ couponCode }
                                                        onChange={ ( e ) => setCouponCode( e.target.value ) }
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                                        placeholder="Enter your exam coupon"
                                                    />
                                                </div>
                                            </div>
                                            <button
                                                onClick={ handleCheckEmail }
                                                className="w-full text-white font-bold rounded-lg text-lg px-5 py-2.5 text-center"
                                                style={ {
                                                    backgroundColor: clientConfig.primary_color,
                                                } }
                                            >
                                                Sign up
                                            </button>
                                            <p className='text-xs font-normal text-black mt-3'>
                                                <span className='font-bold'>NOTE : </span>We might ask you to verify your phone number/email in future via OTP. We humbly request you to enter your valid contact details.
                                            </p>
                                        </div>
                                    </div>
                                ) }
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
};

export default SigninCouponCode;
