import React, { useState, useEffect } from 'react';
import LeftSideBar from '../LeftSideBar';
import { useParams, Link } from 'react-router-dom';
import { dynamicData } from '../../helpers/data';
import ThankyouNnit from './ThankYouPageNniit';
import ThankyouTTM from './ThankYouPageTtm';
import { ThankyouAskIIt, ThankyouAskIItTest } from './ThankYouAskIit';
import { ThankYouJc, ThankYouJcTest } from './ThankYouJitinChawla';
import { useDispatch } from 'react-redux';
import { setIsFormSubmitted } from '../../redux/student/studentSlice';
import { ThankYouInternshala } from './ThankYouInternshala';
import { ThankYouUnityCollege } from './ThankYouUnityCollege';
import { ThankYouWeAce } from './ThankYouWeAce';
import { ThankYouGlobalCareer } from './ThankYouGlobalCareer';
import { ThankYouJaroEdu } from './ThankYouJaroEdu';
import { ThankYouSbit } from './ThankYouSbit';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { ThankYouStride } from './ThankYouStride';
import { ThankYouIVB } from './ThankYouIVB';
import { ThankYouQStudyWorld } from './ThankYouQStudyWorld';
import { ThankYouLetsUnbound } from './ThankYouLetsUnbound';
import ThankYouPageComponentOne from './ThankYouPageComponentOne';

const ThankYouPage = () => {
    const dispatch = useDispatch();
    const { client_name, grade } = useParams()
    const clientConfig = useSelector( state => state.clientConfiguration );

    useEffect( () => {
        if ( clientConfig ) {
            document.title = clientConfig?.tag_line;
            document.querySelector( 'meta[name="description"]' ).setAttribute( "content", clientConfig?.description );
        }
    }, [clientConfig] );

    useEffect( () => {
        Object.keys( localStorage ).forEach( ( key ) => {
            if ( key !== 'student_roll_number' && key != 'studentName' && key != 'user_email' && key !== 'test_query_params' && key !== 'testSeriesId' ) {
                localStorage.removeItem( key );
            }
        } );
        sessionStorage.removeItem( 'flowData' );
        sessionStorage.removeItem( 'currentFlowName' );
        sessionStorage.removeItem( 'currentFlowIndex' );
    }, [] )

    return (
        <div className="container-fluid">
            <Helmet>
                <link rel="icon" href={ clientConfig.favicon } />
            </Helmet>
            <div className="row row-height">
                <LeftSideBar />
                <div className="col-xl-8 col-lg-8 content-right" id="start">
                    <>
                        <div id="wizard_container">
                            <div className="flex flex-col items-center justify-center h-full sm:mt-10 lg:m-0">
                                { ( client_name === "DSAssessment" || client_name === "internshala" ) ?
                                    <ThankYouInternshala /> :
                                    client_name === "jaroedu" ?
                                        <ThankYouJaroEdu /> :
                                        client_name === "weace" ?
                                            <ThankYouWeAce /> :
                                            client_name === "stride" ?
                                                <ThankYouStride /> :
                                                client_name === "bowerschool" ?
                                                    <ThankYouStride /> :
                                                    client_name === "sbit" ?
                                                        <ThankYouSbit /> :
                                                        client_name === "ivb" ?
                                                            <ThankYouIVB /> :
                                                            client_name === 'globalcareers' ?
                                                                <ThankYouGlobalCareer /> :
                                                                client_name === "unitycollege" ?
                                                                    <ThankYouUnityCollege /> :
                                                                    client_name === "strideSurvey"
                                                                        ? <ThankyouNnit studentName="Nnit Student" />
                                                                        : client_name === "ttm"
                                                                            ? <ThankyouTTM studentName="ttm student" />
                                                                            : client_name === "icat"
                                                                                ? !grade ? <ThankyouAskIIt /> : <ThankyouAskIItTest />
                                                                                : client_name === "jc"
                                                                                    ? <ThankYouJcTest />
                                                                                    : <ThankYouPageComponentOne />
                                }
                            </div>
                        </div>
                    </>
                </div>
            </div>
        </div>
    );
};

export default ThankYouPage

